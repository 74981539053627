import {SET_SETTINGS} from '../../actions/app/settings/settings.actions'

export const settingsReducer = (settings = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_SETTINGS) {
        return payload.data
    } else {
        return settings
    }
}
